let dateBlock = document.querySelector('[data-date]'),
    date = new Date(),
    // yy = date.getFullYear() % 100,
    yy = date.getFullYear(),
    mm = date.getMonth() + 1,
    dd = date.getDate(),
    hours = date.getHours(),
    minutes = date.getMinutes()

    // if(yy < 10) {yy = '0' + yy}
    if(mm < 10) {mm = '0' + mm}
    if(dd < 10) {dd = '0' + dd}
  
    dateBlock.innerHTML = `${dd}.${mm}.${yy}`