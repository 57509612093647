"use strict";
const headerBottomBlock     = document.querySelector('.header_bottom'),
      headerBotomFixedBlock = document.querySelector('.header_bottom_fixed')

let last_known_scroll_position = 0;
let ticking = false;

function doSomething(scroll_pos) {
    requireActiveBlock = headerBottomBlock.classList.contains('fixed')
    if(scroll_pos >= 109 && !requireActiveBlock) {
        headerBottomBlock.classList.add('fixed')
    }
    if(scroll_pos < 109 && requireActiveBlock) {
        headerBottomBlock.classList.remove('fixed')
    }

}

window.addEventListener('scroll', function(e) {
  last_known_scroll_position = window.scrollY;

  if (!ticking) {
    window.requestAnimationFrame(function() {
      doSomething(last_known_scroll_position);
      ticking = false;
    });

    ticking = true;
  }
});


const searchBtn      = document.querySelector('.search-btn'),
      searchBlock    = document.querySelector('.search-top'),
      closeBtns      = document.querySelectorAll('.close-btn'),
      subscribeBtns  = document.querySelectorAll('.subscribe-btn'),
      subscribeModal = document.querySelector('.subscribe-modal')



searchBtn.addEventListener('click', function(e) {
    e.preventDefault
    searchBlock.classList.add('active')
    closeBtns.forEach(btn => {
      btn.addEventListener('click', function(e) {
      searchBlock.classList.remove('active')
    })
  })
})

subscribeBtns.forEach(subscribeBtn => {
  subscribeBtn.addEventListener('click', function(e) {
    e.preventDefault
    subscribeModal.classList.add('active')
    closeBtns.forEach(btn => {
      btn.addEventListener('click', function(e) {
        subscribeModal.classList.remove('active')
      })
    })
  })
})

/* playlist */

// const registration = document.querySelector('#registration'),
//       regForm      = document.querySelector('.reg_modal_form'),
//       closeBtn     = document.querySelector('.close') 

// registration.addEventListener('click', function(e) {
//   regForm.classList.add('visible')
// })

// closeBtn.addEventListener('click', function() {
//   regForm.classList.remove('visible')
// })