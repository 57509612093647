if (document.documentElement.clientWidth < 420) {

    const menuBtn        = document.querySelector('.menuBtn'),
          menuBlock      = document.querySelector('.menuBlock')


    menuBtn.addEventListener('click', (e) => {
        e.preventDefault()
        menuBtn.classList.toggle('open')
        menuBlock.classList.toggle('open')

        if(menuBtn.classList.contains('open')) {
            document.body.style.overflowY = 'hidden'
        } else {
            document.body.style.overflowY = ''
        }
    })

}